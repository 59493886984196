import * as React from "react"
import tw from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { graphql, Link, useStaticQuery } from "gatsby"

const NotFoundPage = () => {
  const bgImage = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "sponsor_modal_image.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 1920)
        }
      }
    }
  `)

  return (
    <Layout>
       <Seo title="404: Out of Bounds" />
       <div
         css={[
           tw`relative max-h-screen flex items-center justify-center p-16 bg-primary`,
           "height: 800px;",
         ]}
       >
         <GatsbyImage
           image={getImage(bgImage.file)}
           alt="Bryant Denny Stadium"
           css={[tw`top-0 right-0 w-full h-full object-cover absolute`]}
         />
         <div css={[tw`text-center text-white relative z-20`]}>
           <h1 css={[tw`mb-8`]}>
             <span css={[tw`block text-9xl`]}>404</span>Out of Bounds
           </h1>
           <p>
             Looks like that's not the move you want to make.{" "}
             <Link to="/" css={[tw`underline`]} aria-label="Home">
               Go home.
             </Link>
           </p>
         </div>
       </div>
    </Layout>
  )
}

export default NotFoundPage
